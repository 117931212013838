// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  softwareVersion: 18,
  production: false,
  isolation: '__',
  itemsTimeoutInSeconds: 3600,
  sessionTimeout: 9993600,
  subscribeDashboardSummaryOrderStatus: false,
  subscribeDashboardCompletedOrders: false,
  isEnableHomePage: false,
  imageDomain: 'preprd.bideal-app.com',
  adminEmail: 'versita.store.bd.preprd@gmail.com',
  adminPassword: 'ytmipahtmwpoftto',
  encryptKey: '!Zw8q4ZNc)wuZRDz*LC%8BE?Fr>PK_G:',
  isPhoneEnable: true,
  isEmailEnable: true,
  firebase: {
    apiKey: 'AIzaSyCv_t1TSiojHmBYO2cy0u4iuUdw0fCuJ_A',
    authDomain: 'preprd.bideal-app.com',
    databaseURL: 'https://versita-store-bd-preprd.firebaseio.com',
    projectId: 'versita-store-bd-preprd',
    storageBucket: 'versita-store-bd-preprd.appspot.com',
    messagingSenderId: '341124808782',
    appId: '1:341124808782:web:b0d0a166a8591e5bb0c31f'
  }
};
