import {Injectable, Compiler, Inject} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {environment} from '../../environments/environment';
import {StringUtils} from '../utils/string-utils';
import {Item, ItemVariation, VariationOrder} from '../inventory/model/item';
import {Order} from '../model/order';
import {Message} from '../model/message';
import * as firebase from 'firebase';
import {SharedPreference} from '../shared-preference/shared-perference';
import {Role} from '../model/role';
import {LoggingUtil, LoggUtilInfo} from '../utils/loggUtil';
import {UserProfile} from '../model/user-profile';
import {Phase} from "../model/phase";
import {OrderStatus} from "../model/order-status";
import {OrderUtil} from "../utils/order-util";
import {Brand} from "../inventory/model/brand";
import {ItemCount} from "../inventory/model/item-count";
import {Category} from "../inventory/model/category";
import { HttpClient } from '@angular/common/http';
import {StringFieldUtil} from "../utils/string-field-util";
import {DOCUMENT} from '@angular/platform-browser';
import {StoreUtils} from '../utils/store-utils';
import {CouponCode} from '../model/coupon-code';
import { CDN } from '../model/cdn';
import {DomainNameParserService} from './domain-name-parser.service';
import {Watermark} from "../model/watermark";

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  static isLogging = true;
  static web: string = "web";

  static userCollection = 'user';
  static userCounterCollection = 'userCounter';
  static CustomerCounterDocument = 'customerCounter';
  static userProfileCollection = 'userProfile';
  static itemCollection = 'item';
  static itemSpecialCollection = 'itemSpecial';
  static orderCollection = 'order';
  static categoryCollection = 'category';
  static group = 'group';
  static brandCollection = 'brand';
  static storeCollection = 'store';
  static pathLogoWhiteLabel = 'store/logo';
  static storeSettingCollection = 'storeSetting';
  static defaultDocument = 'default';
  static termConditionCollection = 'termCondition';
  static defaultTermConditionCollection = 'default term and condition';
  static chatCollection = 'message';
  static keyWordsDocument = 'keyword-web';
  static itemWeb = "item-web";
  static keyWordsCollection = 'keyword';
  static documentMapCollection = "documentMap";
  static versionCollection = "version";
  static walletCollection = "wallet";
  static configDocument = "config";
  static releaseCollection = "release";
  static approvalDocument = "approval";
  static orderStatusMapDocument = "orderStatusMap";
  static orderStatusDocument = "orderStatus";
  static systemSettingCollection = "systemSetting";
  static profile = 'profile';
  static orderReportDocument = "orderReport";
  static ratingMapDocument = "ratingMap";
  static ratingCollection = "rating";
  static storeRatingMapDocument = "storeRatingMap";
  static orderReportMapDocument = "orderReportMap";
  static itemStockCollection = 'itemStock';
  static requestDemoCollection = 'requestDemo';
  static unitCostDocument = 'unitCost';
  static storeIds = "storeIds";
  static reviewDocument = 'review';
  static limitationCollection = 'limitation';
  static purchaseDocument = 'purchase';
  static summaryDocument = 'summary';
  static statusSubCollection = 'status';
  static notification = "notification";
  static aboutUs = "aboutUs";
  static contactUs = "contactUs";
  static systemMaintenance = "systemMaintenance";

  public static category: string = environment.isolation + FirebaseService.categoryCollection;
  public static brand: string = environment.isolation + FirebaseService.brandCollection;
  public static user: string = environment.isolation + FirebaseService.userCollection;
  public static userProfile: string = environment.isolation + FirebaseService.userProfileCollection;
  public static userCounter: string = environment.isolation + FirebaseService.userCounterCollection;
  public static storeSetting: string = environment.isolation + FirebaseService.storeSettingCollection;
  public static termsCondition: string = environment.isolation + FirebaseService.termConditionCollection;
  public static defaultTermsCondition: string = environment.isolation + FirebaseService.defaultTermConditionCollection;
  public static item: string = environment.isolation + FirebaseService.itemCollection;
  public static itemStock: string = environment.isolation + FirebaseService.itemStockCollection;
  public static itemSpecial: string = environment.isolation + FirebaseService.itemSpecialCollection;
  public static order: string = environment.isolation + FirebaseService.orderCollection;
  public static chat: string = environment.isolation + FirebaseService.chatCollection;
  public static keyWords: string = environment.isolation + FirebaseService.keyWordsCollection;
  public static orderStatus = environment.isolation + FirebaseService.orderStatusDocument;
  public static documentMap = environment.isolation + FirebaseService.documentMapCollection;
  public static version = environment.isolation + FirebaseService.versionCollection;
  public static wallet = environment.isolation + FirebaseService.walletCollection;
  public static release = environment.isolation + FirebaseService.releaseCollection;
  public static rating = environment.isolation + FirebaseService.ratingCollection;
  public static orderReport = environment.isolation + FirebaseService.orderReportDocument;
  public static systemSetting = environment.isolation + FirebaseService.systemSettingCollection;
  public static defaultSuperAdmin: string = FirebaseService.defaultDocument;
  public static timestamp = environment.isolation + "timestamp";
  public static requestDemo: string = environment.isolation + FirebaseService.requestDemoCollection;
  public static storeList: string = environment.isolation + FirebaseService.storeCollection + "/" +
                                    FirebaseService.storeCollection + "/" + "listStoreId" + "/" + "storeIds" ;
  public static store: string = environment.isolation + FirebaseService.storeCollection + "/" +
                                FirebaseService.storeCollection + "/" + "individual";
  public static storeGroupPath = environment.isolation + FirebaseService.storeCollection + "/" +
    FirebaseService.storeCollection + "/" + FirebaseService.group + "/" + FirebaseService.group;
  public static pushOrderStatus = environment.isolation + "pushOrderStatus";
  public static pushTempPassword = environment.isolation + "pushTempPassword";
  public static register = environment.isolation +"register";
  public static NOTIFICATION = environment.isolation + FirebaseService.notification;
  public static limitation = environment.isolation + FirebaseService.limitationCollection;
  public static reward = "reward";
  public static coupon = "coupon";
  public static credit = "credit";
  public static prefixReward = environment.isolation + FirebaseService.reward;
  public static defaultCouponDocumentPath = `${FirebaseService.prefixReward}/${FirebaseService.reward}/${FirebaseService.defaultDocument}/${FirebaseService.defaultDocument}/${FirebaseService.coupon}`;
  public static defaultCreditDocumentPath = `${FirebaseService.prefixReward}/${FirebaseService.reward}/${FirebaseService.defaultDocument}/${FirebaseService.defaultDocument}/${FirebaseService.credit}`;
  public static aboutUsDocument = `${environment.isolation}/${FirebaseService.aboutUs}/${FirebaseService.aboutUs}`;
  public static contactUsDocument = `${environment.isolation}/${FirebaseService.contactUs}/${FirebaseService.contactUs}`;
  public static tempOrder: string = environment.isolation + "onlinePayment/temp_order/temp_order";


  public static getPurchasePathByStoreId(storeId: string, itemId: string) {
    return `${FirebaseService.limitation}/${FirebaseService.purchaseDocument}/${storeId}/${itemId}`;
  }

  public static getCouponDocumentPathByStoreId(storeId: string) {
    return `${FirebaseService.prefixReward}/${FirebaseService.reward}/store/${storeId}/${FirebaseService.coupon}`;
  }

  public static getCreditDocumentPathByStoreId(storeId: string) {
    return `${FirebaseService.prefixReward}/${FirebaseService.reward}/store/${storeId}/${FirebaseService.credit}`;
  }

  public static storeLocation = environment.isolation + FirebaseService.storeCollection + "/" +
    FirebaseService.storeCollection + "/" + "storeLocation";

  public static storeReview: string = environment.isolation + FirebaseService.storeCollection
                                      + "/" +FirebaseService.reviewDocument;

  public static getCustomerReviewPath(storeId: string){
    return environment.isolation + FirebaseService.storeCollection + "/" + storeId +
      "/"+ FirebaseService.reviewDocument + "/" + FirebaseService.ratingCollection + "/" + "customerRating";
  }

  public static getItemCountPath(storeId: string, document: string){
    return environment.isolation + FirebaseService.storeCollection +"/inventory/"+ storeId +"/"+ document;
  }

  public static getItemPath(){
    return this.item +"/"+ FirebaseService.itemCollection +"/"+ SharedPreference.getStoreId();
  }

  public static getItemPathWithStoreId(storeId){
    return this.item +"/"+ FirebaseService.itemCollection +"/"+ storeId;
  }

  public static getItemStockPath(){
    const storeId = (Role.hasVersitaAdmin(SharedPreference.getUser())
                      || Role.hasVersitaCustomerService(SharedPreference.getUser())
                      || Role.hasVersitaSupervisor(SharedPreference.getUser())) ? StoreUtils.getSelectStoreId() : SharedPreference.getStoreId();
    return this.itemStock +"/"+ FirebaseService.itemStockCollection +"/"+ storeId;
  }

  public static getUnitCostPath(){
    const storeId = (Role.hasVersitaAdmin(SharedPreference.getUser())
      || Role.hasVersitaCustomerService(SharedPreference.getUser())
      || Role.hasVersitaSupervisor(SharedPreference.getUser())) ? StoreUtils.getSelectStoreId() : SharedPreference.getStoreId();
    return environment.isolation + FirebaseService.storeCollection +"/inventory/"+
      storeId +"/item/"+ FirebaseService.unitCostDocument;
  }

  public static getDocumentMapPath(storeId: string){
    return this.documentMap +"/"+ this.storeCollection +"/"+ storeId;
  }

  public static getOrderStatusPath(storeId: string){
    return this.orderStatus +"/"+ this.storeCollection +"/"+ storeId;
  }

  public static getOrderReportPath(storeId: string){
    return this.orderReport +"/"+ this.storeCollection +"/"+ storeId;
  }

  public static getOrderPath(storeId: string){
    return this.order +"/"+ this.storeCollection +"/"+ storeId;
  }

  public static getRatingPath(storeId: string){
    return this.rating +"/"+ this.storeCollection +"/"+ storeId;
  }

  public static getPathCounterInventory(path: string){
    return  environment.isolation + FirebaseService.storeCollection +"/inventory/"+ path;
  }

  public static getReleasePath(){
    return environment.isolation +  FirebaseService.releaseCollection + "/" + FirebaseService.approvalDocument;
  }

  constructor(
    private compiler: Compiler,
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    public db: AngularFirestore,
    public storage: AngularFireStorage,
    private domainNameParserService: DomainNameParserService
  ) {}

  //TODO: *******************************onCreateOrUpdate*******************************

  onCreateOrUpdateDataById(path: string, uid: string, object: any, isUser?: boolean, isLoad?: boolean) {
    LoggingUtil.logging('onCreateOrUpdateDataById: ' + path + '=' + uid);
    if (isUser) {
      let userProfile: UserProfile = new UserProfile();
      userProfile.clone(object);
      this.db.doc(FirebaseService.userProfile + '/' + userProfile.uid).set(userProfile.toMap(), {merge: true});
    }
    return !isLoad ? this.db.doc(path + '/' + uid).set(object.toMap(), {merge: true}) : undefined;
  }

  onCreateOrUpdateDocument(path: string, object: any, isSlideShow?: boolean){
    LoggingUtil.logging("onCreateOrUpdateADocument: "+path);
    if(isSlideShow)
      return this.db.doc(path).set(object.toMap(true), {merge: true});

    return this.db.doc(path).set(object.toMap(), {merge: true});
  }

  onCreateCollectAndDocument(collection: string, document: string){
    return this.db.collection(collection).doc(document).set({});
  }

  onUpdateCouponCode(couponCode: CouponCode, path: string) {
    return this.db.doc(path).set(couponCode.toMap(), {merge: true
    })
  }

  onUpdateCDNConfig(cdnConfig: CDN, path: string) {
    return this.db.doc(path).set({cdn: cdnConfig.toMap()}, {merge: true})
  }

  onUpdateWatermark(watermark: Watermark, path: string) {
    return this.db.doc(path).set({watermark: watermark.toMap()}, {merge: true})
  }

  onBatchCommitCouponCodes(path: string, couponCodes: CouponCode[]) {
    let batch = this.db.firestore.batch();
    couponCodes.forEach((coupon) => {
      let couponRef = this.db.firestore.doc(`${path}/${coupon['code']}`);
      batch.set(couponRef, coupon,{merge: true});
    });
    return batch.commit();
  }

  onBatchDeleteCouponCodes(path: string, couponCodes: CouponCode[]) {
    let batch = this.db.firestore.batch();
    couponCodes.forEach((coupon) => {
      let couponRef = this.db.firestore.doc(`${path}/${coupon['code']}`);
      batch.delete(couponRef);
    });
    return batch.commit();
  }

  onCreateOrUpdateEntryField(path: string, keyId: string, data: any) {
    let map: Map<string, any> = new Map();
    map.set(keyId, data.toMap());
    let dataDB = StringUtils.strMapToObj(map);
    LoggingUtil.logging("onCreateOrUpdateEntryField: "+path+"/"+keyId);
    return this.db.doc(path).set(dataDB, {merge: true});
  }

  onCreateOrUpdateBrand(data: Brand, isEdit: boolean) {
    let batch = this.db.firestore.batch();
    let refBrand = this.db.firestore.doc(FirebaseService.brand +"/"+ FirebaseService.brandCollection);
    let brand = Brand.clone(data);

    if(!isEdit){
      if(!Role.hasVersitaAdmin(SharedPreference.getUser())) {
        let refItemCount = this.db.firestore.doc(FirebaseService
          .getPathCounterInventory(SharedPreference.getStoreId()) +"/"+ FirebaseService.brandCollection
        );
        let itemCount = new ItemCount(0, 0);
        let toMap = new Map();
        toMap.set(brand.id, itemCount.toMap());

        batch.set(refItemCount, StringUtils.strMapToObj(toMap), {merge: true});
      }
    }

    brand.modifiedAt = StringUtils.getDateLocal(StringUtils.getDateUTC(new Date()));
    brand.itemCountTotal = 0;

    let mapBrand = new Map();
    mapBrand.set(brand.id, brand.toMap());
    batch.set(refBrand, StringUtils.strMapToObj(mapBrand), {merge: true});

    LoggingUtil.logging("onCreateOrUpdateBrand: "+refBrand.path+"/"+brand.id);
    return batch.commit();
  }

  onCreateOrUpdateCategory(data: Category, isEdit: boolean) {
    let batch = this.db.firestore.batch();
    let refCategory = this.db.firestore.doc(FirebaseService.category +"/"+ FirebaseService.categoryCollection);
    let category = Category.clone(data);

    if(!isEdit){
      if(!Role.hasVersitaAdmin(SharedPreference.getUser())) {
        let refItemCount = this.db.firestore.doc(FirebaseService
          .getPathCounterInventory(SharedPreference.getStoreId()) +"/"+ FirebaseService.categoryCollection
        );
        let itemCount = new ItemCount(0, 0);
        let toMap = new Map();
        toMap.set(category.id, itemCount.toMap());
        batch.set(refItemCount, StringUtils.strMapToObj(toMap), {merge: true});
      }
    }

    category.modifiedAt = StringUtils.getDateLocal(StringUtils.getDateUTC(new Date()));
    category.itemCountTotal = 0;

    let toMap = new Map();
    toMap.set(category.id, category.toMap());
    batch.set(refCategory, StringUtils.strMapToObj(toMap), {merge: true});

    LoggingUtil.logging("onCreateOrUpdateCategory: "+refCategory.path+"/"+category.id);
    return batch.commit();
  }

  onUpdateEntryField(path: string, keyId: string, data: any) {
    let map: Map<string, any> = new Map();
    map.set(keyId, data.toMap());
    let dataDB = StringUtils.strMapToObj(map);
    LoggingUtil.logging("onCreateOrUpdateEntryField: "+path+"="+keyId);
    return this.db.doc(path).update(dataDB);
  }

  onUpdateVariantEntryField(path: string, itemId: string, keyId: string, data: any, barcodes: any[], variationOrder: VariationOrder) {
    let map: Map<string, any> = new Map();
    map.set(itemId+".variation."+keyId, data.toMap());
    map.set(itemId+".barcodes", barcodes);
    map.set(itemId+".variationOrder", variationOrder.toMap());
    map.set(itemId+".modifiedAt", StringUtils.getDateLocal(StringUtils.getDateUTC(new Date())));
    let dataDB = StringUtils.strMapToObj(map);
    LoggingUtil.logging("onCreateOrUpdateEntryField: "+path+"="+keyId);
    return this.db.doc(path).update(dataDB);
  }

  onCreateOrUpdateOrderReport(path: string, keyId: string, data: any, startDate: string, endDate: string) {
    let map: Map<string, any> = new Map();
    map.set(keyId, data.toMap());
    if(startDate != null){
      map.set('startDate', startDate);
    }
    map.set('endDate', endDate);

    let dataDB = StringUtils.strMapToObj(map);
    LoggingUtil.logging("onCreateOrUpdateOrderReport: "+path+"="+keyId);
    return this.db.doc(path).set(dataDB, {merge: true});
  }

  onCreateKeyWord(docId: string,  data: any) {
    let map: Map<string, any> = new Map();
    map.set("keywords", data);
    let dataDB = StringUtils.strMapToObj(map);
    LoggingUtil.logging("onCreateKeyWord: "+docId);
    return this.db.doc(FirebaseService.keyWords + '/' + docId ).set(dataDB, {merge: true});
  }

  onCreateOrUpdateChat(message: Message, mid: string, docId: string) {
    let map: Map<string, any> = new Map();
    map.set(mid, message.toMap());
    map.set(StringUtils.lastMessageDate, firebase.firestore.Timestamp.now());
    let data = StringUtils.strMapToObj(map);
    LoggingUtil.logging("onCreateOrUpdateChat: "+docId+"="+mid);
    return this.db.doc(
      FirebaseService.chat +"/"+ FirebaseService.chatCollection +'/'+ SharedPreference.getStoreId() +"/"+ docId
    ).set(data, {merge: true});
  }

  //TODO: *******************************onRead*******************************

  onReadDocument(collection: string, document: string){
    LoggUtilInfo.loggingStart(StringUtils.readDocument + collection + "/" + document);
    return this.db.collection(collection).doc(document).ref.get();
  }

  onGetDataSnapshotChanges(collection: string){
    LoggUtilInfo.loggingStart(StringUtils.listenSnapshot + collection + " - onGetDataSnapshotChanges @" + new Date().toLocaleTimeString());
    return this.db.collection(collection).snapshotChanges();
  }

  onGetDataDocument(path: string){
    LoggUtilInfo.loggingStart(StringUtils.listenSnapshot + path + " - onGetDataDocument");
    return this.db.doc(path).valueChanges();
  }

  onGetCollection(path: string, limit = 0){
    LoggUtilInfo.loggingStart(StringUtils.readDocument + path);
    let collectionRef = this.db.collection(path).ref;
    if(limit > 0){
      return collectionRef.limit(limit).get();
    }
    return collectionRef.get();
  }

  onGetNotificationsByTopics(path: string, bundleIds: any[]){
    LoggUtilInfo.loggingStart(StringUtils.readDocument + path);
    console.log("bundleIds :: ", bundleIds);
    return this.db.collection(path, ref => ref.where("topics", "array-contains-any", bundleIds)).get();
  }

  onGetDataDocumentStoreList(path: string){
    LoggUtilInfo.loggingStart(StringUtils.readDocument + path);
    return this.db.doc(path).ref.get();
  }

  onGetDataValueChanges(collection: string, document: string){
    LoggUtilInfo.loggingStart(StringUtils.listenSnapshot + collection + "/" + document + " - onGetDataValueChanges");
    return this.db.collection(collection).doc(document).valueChanges();
  }

  onGetDataById(collection: string, path: string){
    LoggUtilInfo.loggingStart(StringUtils.listenSnapshot + collection + "/" + document + " - onGetDataById");
    return this.db.collection(collection).doc<any>(path).valueChanges();
  }
  onGetItemByDocPath(docPath: string) {
    LoggUtilInfo.loggingStart(StringUtils.readDocument + FirebaseService.getItemPath() + '/' + docPath);
    return this.db.doc<Item>(FirebaseService.getItemPath() + '/' + docPath).ref.get();
  }

  onGetOrderByMonth(startMonth: string, endMonth: string) {
    LoggUtilInfo.loggingListen(StringUtils.listenSnapshot + `${FirebaseService.getOrderPath(SharedPreference.getStoreId()) + "startMonth: " + startMonth + " endMonth: " + endMonth}`);
    return this.db.collection(
      FirebaseService.getOrderPath(SharedPreference.getStoreId()),
      ref => ref
        .orderBy('orderDate')
        .startAt(startMonth)
        .endAt(endMonth)
    ).snapshotChanges();
  }

  onGetOrderList(storeId : string, orderIds : string[]){
     return this.db.collection(FirebaseService.getOrderPath(storeId), ref =>
      ref
        .where("id", "in", orderIds)
    ).get();
  }

  onGetOrderLateDate(storeId : string){
    return this.db.collection(FirebaseService.getOrderPath(storeId), ref =>
      ref
        .orderBy("orderDate", "desc")
        .limit(1)
    ).get();
  }

  onGetTempOrderList(){
    console.log("onGetTempOrderList", FirebaseService.tempOrder);
    return this.db.collection(FirebaseService.tempOrder).get();
  }

  onGetUser(email?: string, storeId?: string, isFromBank?: boolean) {
    LoggUtilInfo.loggingStart(StringUtils.listenSnapshot + FirebaseService.user + " - onGetUser");
    if(Role.hasAdminRole(SharedPreference.getUser())){
      return this.db.collection(FirebaseService.user, ref =>
        ref
          // .where('roles', 'array-contains', StringUtils.internalUser) [only support a single array-contains filter]
          .where("isDelete", "==", false)
          .where("storeIds", "array-contains", SharedPreference.getUser().currentStoreId)
      ).get();
    }else if(email && Role.hasSupervisorRole((SharedPreference.getUser()))) {
      return this.db.collection(FirebaseService.user, ref =>
        ref
          .where('roles', 'array-contains', StringUtils.customer)
          .where('email','>=', email).limit(10)
          // .orderBy('email').startAt(email).endBefore(email+'\uf8ff').limit(10)
      ).get();
    }else if(!email && Role.hasSupervisorRole((SharedPreference.getUser()))){
      return this.db.collection(FirebaseService.user, ref =>
        ref
          .where('roles', 'array-contains', StringUtils.customer)
      ).get();
    } else if (Role.hasVersitaSystem(SharedPreference.getUser())) {
      return this.db.collection(FirebaseService.user, ref =>
        ref
          .where('roles', 'array-contains', StringUtils.versitaUser)
          .where("isDelete", "==", false)
      ).get();
    }else if (Role.hasVersitaAdmin(SharedPreference.getUser())) {
      if (!email) {
        if(isFromBank){
          return this.db.collection(FirebaseService.user, ref =>
            ref
              .where("isDelete", "==", false)
              .where("roles", "array-contains", "Bank")
          ).get();
        }

        return this.db.collection(FirebaseService.user, ref =>
          ref
            .where("isDelete", "==", false)
            .where("storeIds", "array-contains", storeId)
        ).get();
      }
    }
  }

  onGetUserForVersitaAdmin(keySearch?: string) {
    let collectionRef = this.db.collection(FirebaseService.user).ref;
    //use this below code for purpose query data with operation "OR" like in SQL

    if(!isNaN(Number(keySearch))){
      let check = keySearch.split("");

      if(check[0].includes("8") && check[1].includes("5") && check[2].includes("5")){
        keySearch = keySearch.substring(3, keySearch.toString().length);
      }

      if(keySearch.split("")[0].includes("0")){
        keySearch = keySearch.substring(1, keySearch.toString().length);
      }

      return Promise.all([
        collectionRef.where('phoneNumber.phone', '>=', keySearch).limit(10).get()
      ]).then(([a]) => [...a.docs].filter(doc=>JSON.stringify(doc.data()).toLocaleLowerCase().includes(keySearch.toLocaleLowerCase())));

    } else {
      return Promise.all([
        collectionRef
          .where('firstName', '>=', keySearch)
          .where('firstName', '<=', keySearch).limit(20).get(),
        collectionRef
          .where('lastName', '>=', keySearch)
          .where('lastName', '<=', keySearch).limit(20).get(),
      ]).then(([a, b]) => [...a.docs, ...b.docs].filter(doc=>JSON.stringify(doc.data()).toLocaleLowerCase().includes(keySearch.toLocaleLowerCase())));
    }



  }

  onGetUserProfileByUids(uid: string) {
    return this.db.collection(FirebaseService.userProfile, ref =>
    ref.where('uid', '==', uid)).get();
  }

  onGetOnlyCustomers() {
      return this.db.collection(FirebaseService.user, ref =>
        ref.where("roles", "array-contains", StringUtils.customer)
      ).valueChanges();
  }

  async onGetUserWithPagination(lastUid?: string) {
    let ref = this.db.collection(FirebaseService.user).ref;
    if (lastUid) {
      LoggUtilInfo.loggingStart(lastUid);
      let lastDuc = await this.db.collection(FirebaseService.user).doc(lastUid).ref.get();
        return ref.startAfter(lastDuc).limit(12).get();
    } else {
      return ref.limit(12).get();
    }
  }

  onGetUserByStoreId(storeId: string) {
    LoggUtilInfo.loggingStart(StringUtils.readDocument + FirebaseService.user);
    return this.db.collection(FirebaseService.user, ref =>
      ref.where("storeIds", "array-contains", storeId)
    ).get();
  }

  onGetChat(messageDisplayDay : number, storeId: string){
    let path = FirebaseService.chat +"/store/" + storeId +"/message";
    LoggUtilInfo.loggingStart(StringUtils.listenSnapshot + path + `${StringUtils.lastMessageDate} >= ${messageDisplayDay}` + " - onGetChat");
    return this.db.doc(path).valueChanges();
  }

  onGetChatByUid(uid: string, storeId: string){
    let path = FirebaseService.chat +"/message/"+ storeId +"/"+ uid;
    LoggUtilInfo.loggingStart(StringUtils.listenSnapshot + path + " - onGetChatByUid");
    return this.db.doc(path).valueChanges();
  }

  onGetOrderReportByMonth(startMonth: string, endMonth: string, storeId: string) {
    LoggUtilInfo
      .loggingStart(StringUtils.readDocument + FirebaseService.getOrderReportPath(storeId) + "orderBy: startDate " + `startAt ${startMonth} -- endAt ${endMonth}`);
    return this.db.collection(
      FirebaseService.getOrderReportPath(storeId),
      ref => ref
        .orderBy('startDate')
        .startAt(startMonth)
        .endAt(endMonth)
    );
  }

  onGetOrderByDate(startDate: string, endDate: string, storeId: string) {
    LoggUtilInfo
      .loggingStart(StringUtils.readDocument + FirebaseService.getOrderPath(storeId) + "orderBy: startDate " + `startDate ${startDate} -- startEnd ${endDate}`);
    return this.db.collection(
      FirebaseService.getOrderPath(storeId),
      ref => ref
        .where('orderDate', '>=', `${startDate} 00:00:00.000000Z`)
        .where('orderDate', '<=', `${endDate} 23:59:59.999999Z`)
    );
  }

  //TODO: *******************************onDelete*******************************

  onDelete(collection: string, id: string) {
    LoggingUtil.logging("onDelete");
    this.db.doc(collection + '/' + id).delete();
  }

  onDeleteCollection(collection: string, path: string, callBack?: Function) {
    LoggingUtil.logging("onDeleteCollection: "+collection+"="+path);
    this.db.collection(collection).doc(path).delete().then(value => {
      callBack ? callBack(true) : undefined;
    }).catch(err => {
      callBack(false);
      LoggUtilInfo.loggingError('onDeleteCollection => ' + collection, err.toString());
    });
  }

  onDeleteAllSubDocument(collection: string, callBack?: Function){
    LoggingUtil.logging("onDeleteAllSubDocument: "+collection);
    this.db.collection(collection)
      .ref.get().then((data)=>{
        if(!data.empty){
          data.docs.forEach((docU, index)=>{
            this.onDeleteCollection(collection,docU.id, function (result) {
              if(data.docs.length -1 == index){
                callBack ? callBack(result) : null;
              }
            });
          });
        }else {
          callBack ? callBack(false) : null;
          LoggUtilInfo.loggingError('no collection onDeleteAllSubDocument => ' + collection);
        }
    }).catch(err => {
      callBack ? callBack(false) : null;
      LoggUtilInfo.loggingError('onDeleteAllSubDocument => ' + collection, err.toString());
    });
  }

  purgeCDN(url: string) {
    if(this.domainNameParserService.isCrossDomainNotAllowed(url)) {
      LoggUtilInfo.loggingError(`PURGE: Cross Domain not allowed - ${this.document.location.hostname} - request to ${url}`);
      return;
    }
    LoggUtilInfo.loggingStart(`Purging: ${url}`);
    this.http.request('PURGE',url).subscribe();
    this.http.request('PURGE',url.replace(StringUtils.CDNWidth, StringUtils.CDNLargeWidth)).subscribe();
    this.http.request('PURGE',url.replace(StringUtils.CDNWidth, StringUtils.CDNMetadata)).subscribe();
    this.compiler.clearCache();
  }

  onDeleteUrlImage(imageUrl: string) {
    return new Promise(resolve => {
      if(!imageUrl.includes(StringUtils.firestoreDomain)) {
        let cdnUrl: string = imageUrl;
        let domain = this.document.location.hostname;
        // if(this.domainNameParserService.isCrossDomainNotAllowed(imageUrl)) {
        //   LoggUtilInfo.loggingError(`DELETE: Cross Domain not allowed - ${domain} - request to ${imageUrl}`);
        //   return resolve();
        // }

        // For Deleting Image from Different Environment
        /*this.http.request('PURGE',metadataUrl).subscribe(data => {
          this.http.get(metadataUrl).subscribe(data  =>{
            if(data['bucket']) {
              let fileName = imageUrl.split("/").pop();
              let bucket = data['bucket'];
              imageUrl = `https://${StringUtils.firestoreDomain}/v0/b/${bucket}/o/${fileName}`;
              LoggingUtil.logging("onDeleteUrlImage => "+imageUrl);
              this.storage.storage.refFromURL(imageUrl).delete().catch((e) => {
                LoggUtilInfo.loggingError(`onDeleteUrlImage: Image not found`);
              }).finally(() =>  {
                this.purgeCDN(cdnUrl);
                resolve();
              });
            }
          });
        }, error => {this.purgeCDN(cdnUrl); LoggUtilInfo.loggingError(`onDeleteUrlImage: Image not found ${cdnUrl}`); resolve();}
        );*/

        let fileName = imageUrl.split("/").pop().includes('?') ? imageUrl.split("/").pop().split("?")[0] : imageUrl.split("/").pop();
        let bucket = environment.firebase.storageBucket;
        imageUrl = `https://${StringUtils.firestoreDomain}/v0/b/${bucket}/o/${fileName}`;
        LoggingUtil.logging("onDeleteUrlImage => "+imageUrl);
        this.storage.storage.refFromURL(imageUrl).delete().catch((e) => {
          LoggUtilInfo.loggingError(`onDeleteUrlImage: Image not found`);
        }).finally(() => {
          // this.purgeCDN(cdnUrl);
          resolve();
        });
      }
      else {
        LoggingUtil.logging("onDeleteUrlImage => "+imageUrl);
        this.storage.storage.refFromURL(imageUrl).delete().catch((e) => {
          LoggUtilInfo.loggingError(`onDeleteUrlImage ${e}`);
        }).finally(() => resolve());
      }
    });
  }

  onDeleteEntryField(path: string, keyId: string) {
    let map: Map<string, any> = new Map();
    let deleteField = firebase.firestore.FieldValue.delete();
    map.set(keyId, deleteField);
    let dataDB = StringUtils.strMapToObj(map);
    LoggingUtil.logging("onDeleteEntryField: "+path+"="+keyId);
    return this.db.doc(path).update(dataDB);
  }

  onDeleteVariantEntryField(path: string, item: Item, variant: ItemVariation, variationOrder: VariationOrder) {
    let map: Map<string, any> = new Map();
    let deleteField = firebase.firestore.FieldValue.delete();
    map.set(item.id+".variation."+variant.barcode, deleteField);
    map.set(item.id+".barcodes", item.barcodes);
    map.set(item.id+".variationOrder", variationOrder.toMap());
    map.set(item.id+".modifiedAt", StringUtils.getDateLocal(StringUtils.getDateUTC(new Date())));
    let dataDB = StringUtils.strMapToObj(map);
    LoggingUtil.logging("onDeleteEntryField: "+path+"/"+item.id+"/"+variant.barcode);
    return this.db.doc(path).update(dataDB);
  }

  updateEntryField(path: string, keyId: string, data: any) {
    let map: Map<string, any> = new Map();
    map.set(keyId, data.toMap());
    let dataDB = StringUtils.strMapToObj(map);
    LoggingUtil.logging("updateEntryField: "+path+"="+keyId);
    return this.db.doc(path).update(dataDB);
  }

  updateFieldIsEnable(path: string, isEnable: boolean, field: string) {
    let map: Map<string, any> = new Map();
    map.set(field, isEnable);
    let dataDb = StringUtils.strMapToObj(map);
    return this.db.doc(path).update(dataDb);
  }

  updateMap(document: string, map: Map<string, Object>) {
    const mapToFirebase = StringUtils.strMapToObj(map);
    return this.db.doc(document).update(mapToFirebase);
  }

  onUpdateTrackingStatus(order: Order, trackingStatus: string, phase: Phase) {
    let map: Map<string, any> = new Map();
    let histories = [];
    order.tracking.phaseHistories.forEach((history)=>{
      histories.push(history.toMap());
    });

    histories.splice(histories.indexOf(phase), 1);
    histories.push(phase.toMap());
    map.set("tracking.currentStatus", order.tracking.currentStatus.toMap());
    map.set("tracking."+trackingStatus, phase.toMap());
    map.set("orderStatusPath", order.orderStatusPath);
    map.set("tracking.phaseHistory", histories);
    map.set("isPaid", order.isPaid);
    let dataDB = StringUtils.strMapToObj(map);
    LoggingUtil.logging("onUpdateTrackingStatus: "+order.id);
    return this.db.doc(FirebaseService.getOrderPath(order.storeId) +"/"+ order.id).update(dataDB);
  }

  onDeleteOrderStatus(order: Order) {
    let map: Map<string, any> = new Map();
    let deleteField = firebase.firestore.FieldValue.delete();
    map.set(order.id, deleteField);
    let dataDB = StringUtils.strMapToObj(map);
    LoggingUtil.logging("deleteOrderStatus: " + order.id);
    return this.db.doc(FirebaseService.getOrderStatusPath(order.storeId) + "/" + OrderUtil.orderStatusDocument).update(dataDB);
  }

  onUpdateOrderStatus(order: Order){
    let orderStatus: OrderStatus = new OrderStatus();
    orderStatus.id = order.id;
    orderStatus.storeId = order.storeId;
    orderStatus.orderDate = order.orderDate;
    orderStatus.customerName = order.shippingAddress.getFullName();
    orderStatus.customerId = order.customerId;
    orderStatus.total = order.total;
    orderStatus.documentPath = order.orderStatusPath;
    orderStatus.orderStatus = order.tracking.currentStatus.status;
    orderStatus.isPaid = order.isPaid;

    let map: Map<string, any> = new Map();
    map.set(order.id, orderStatus.toMap());
    let dataDB = StringUtils.strMapToObj(map);
    LoggingUtil.logging("onUpdateOrderStatus: " + order.id);
    return this.db.doc(FirebaseService.getOrderStatusPath(orderStatus.storeId) +"/"+ StringUtils.getLastPath(order.orderStatusPath)).set(dataDB, {merge: true});
  }

  getRegisterByStore(storeId: string, isVersitaSystem?: boolean, isFromBank?: boolean){
    LoggUtilInfo.loggingStart(StringUtils.readDocument + FirebaseService.register +"/"+ storeId);
    if(isFromBank){
      return this.db.collection(FirebaseService.register,
        ref => ref.where(StringFieldUtil.roles, 'array-contains', StringUtils.roleBank)
      ).get();
    }

    if(isVersitaSystem){
      return this.db.collection(FirebaseService.register,
        ref => ref.where(StringFieldUtil.roles, 'array-contains', StringUtils.versitaUser)
      ).get();
    }

    return this.db.collection(FirebaseService.register,
      ref => ref.where(StringFieldUtil.storeIds, 'array-contains', storeId)
    ).get();
  }
}
