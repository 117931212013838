import { Injectable } from '@angular/core';
import {io} from 'socket.io-client';
import {AesHelper} from '../../shared-preference/aes-helper';
import {DateFormat} from '../../utils/datetime';


@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private wsServerUrl = 'https://bideal-socket-xea4a.ondigitalocean.app/';
  private socket = io(
    this.wsServerUrl,
    {reconnection: true, autoConnect: true, auth: {token: AesHelper.encrypt(JSON.stringify({'expiration': DateFormat.getTimeStamp(150)}))},
    }
  );

  constructor() {

    this.socket.on("connect", () => {
      console.log("connected :: ",this.socket.id);
    });

    this.socket.on("disconnect", () => {
      console.log("disconnect :: ",this.socket.id);
    });
  }

  commonBidItemQuery(query = {}, keyword = '*', page = ''){
    return new Promise((resolve, reject) => {
      try {
        const queryHeader = {
          'use_cache': false,
          'q': keyword,
          'page': page,
          ...query
        };

        console.log('==>>searchBid queryHeader ', queryHeader);
        this.socket.emit('searchBid', queryHeader, (response) => {
          resolve(response);
        });
      } catch (error) {
        reject([]);
      }
    });
  }

  searchBidItems(filter_by = undefined, keyword = '*', page = 'all', filter = undefined, type = undefined, status = undefined, publish = undefined, per_page = undefined, only_documents = false) {
    if(filter_by){
      if(filter)
        filter_by = filter_by + ` && filter:="${filter}"`;

      if(type)
        filter_by = filter_by + ` && type:="${type}"`;

      if(status)
        filter_by = filter_by + ` && status:="${status}"`;

      if(publish === true || publish === false)
        filter_by = filter_by + ` && publish:=[${publish}]`;
    }

    const query = {filter_by, per_page, 'onlyDocuments': only_documents, query_by: '_id, keywords'};
    return this.commonBidItemQuery(query, keyword, page);
  }

  commonBidRecordQuery(query = {}, keyword = '*', page = ''){
    return new Promise((resolve, reject) => {
      try {
        const queryHeader = {
          'use_cache': false,
          'q': keyword,
          'page': page,
          ...query
        };

        console.log('==>>searchBidRecord queryHeader ', queryHeader);
        this.socket.emit('searchBidRecord', queryHeader, (response) => {
          resolve(response);
        });
      } catch (error) {
        reject([]);
      }
    });
  }

  searchBidRecords(bidId:string = undefined, per_page = '250', bidderId: string = undefined, filter_by = undefined, keyword = '*', page = '1') {
    let bidder = bidderId ? ` && bidder:=${bidderId}` : '';
    if(bidId){
      const bidItem = `bidItem:=${bidId}${bidder}`;
      filter_by = filter_by ? bidItem + " && " + filter_by : bidItem;
    }

    if(!bidId && bidderId){
      filter_by = filter_by ? filter_by + bidder : bidder;
    }

    const query = {filter_by, per_page: per_page, listen: false, query_by: 'phoneNumber'};
    return this.commonBidRecordQuery(query, keyword, page);
  }

  commonBidReportQuery(isListen: boolean, storeId, startDate: 0, endDate = 0, callback){
    return new Promise((resolve, reject) => {
      try {
        this.socket.emit('stats', {storeId, startDate, endDate});

        if(isListen){
          this.socket.on('onStatsUpdate', (data)=>{
            callback(data);
          });
        }else {
          this.socket.once('onStatsUpdate', (data)=>{
            callback(data);
          });
        }
      } catch (error) {
        reject([]);
      }
    });
  }
}
