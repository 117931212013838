import {SystemConfiguration} from '../model/system-configuration';
import {FirebaseService} from '../services/firebase.service';
import {SubscriptionUtils} from "./subscriptionUtils";
import {LoggUtilInfo} from './loggUtil';
import {StringUtils} from './string-utils';
import {OrderReportMap} from '../model/order-report-map';
import {OrderRatingMap} from '../model/order-rating-map';
import {ActiveOrderStatus, OrderStatusMap} from '../model/active-order-status';

export class SystemConfigUtils{
  private static systemConfigure: SystemConfiguration = new SystemConfiguration();
  static subscriptionUtils : SubscriptionUtils = new SubscriptionUtils();

  static onGetSystemConfiguration(firebaseService: FirebaseService , callBack?: Function){
    firebaseService.onReadDocument(FirebaseService.systemSetting, FirebaseService.defaultSuperAdmin).then(value => {
      if (value.data()) {
        this.setSystemConfig(value);
        callBack ? callBack(SystemConfigUtils.systemConfigure) : null;
        LoggUtilInfo.loggingEnd(StringUtils.readDocument + FirebaseService.systemSetting +"/"+ FirebaseService.defaultSuperAdmin + StringUtils.componentSystemConfigUtil);
      } else {
        console.log('No Documents of System Configurations.', StringUtils.componentSystemConfigUtil);
      }
    }).catch((e)=>{
      LoggUtilInfo.loggingError(FirebaseService.systemSetting +"/" + FirebaseService.defaultSuperAdmin+ "/" +e.toString(), StringUtils.componentSystemConfigUtil);
    });
  }

  static unsubscribeSystemConfiguration(){
    this.subscriptionUtils.unsubscribeAll();
  }

  static initNewDocMap(firebaseService: FirebaseService, storeId: string) {
    console.log('===>Starting Create<===');

    let docName = StringUtils.getYYYYMM(new Date()) + '-000000';
    let pathDocOrderReport = FirebaseService.getDocumentMapPath(storeId) + "/" + FirebaseService.orderReportMapDocument;
    let pathDocRating = FirebaseService.getDocumentMapPath(storeId) + "/" + FirebaseService.ratingMapDocument;
    let pathDocStoreRating = FirebaseService.getDocumentMapPath(storeId) + "/" + FirebaseService.storeRatingMapDocument;

    let orderReport: OrderReportMap = new OrderReportMap();
    orderReport.currentReportDocument = 0.0;
    orderReport.documentName = docName;
    orderReport.maxRecord = this.systemConfigure.maxRecordReport;
    orderReport.recordCount = 0.0;
    firebaseService.onCreateOrUpdateEntryField(pathDocOrderReport, FirebaseService.orderReportDocument, orderReport);

    let orderRating: OrderRatingMap = new OrderRatingMap();
    orderRating.currentReportDocument = 0.0;
    orderRating.documentName = docName;
    orderRating.maxRecord = this.systemConfigure.maxRecordRating;
    orderRating.recordCount = 0.0;
    firebaseService.onCreateOrUpdateEntryField(pathDocRating, FirebaseService.ratingCollection, orderRating);

    let storeRating: OrderRatingMap = new OrderRatingMap();
    storeRating.currentReportDocument = 0.0;
    storeRating.documentName = docName;
    storeRating.maxRecord = this.systemConfigure.maxRecordRating;
    storeRating.recordCount = 0.0;
    firebaseService.onCreateOrUpdateEntryField(pathDocStoreRating, FirebaseService.ratingCollection, storeRating);

    StringUtils.allStatus.forEach(status => {
      this.onCreateNewOrderStatusMap(status, firebaseService, storeId);
    });
    console.log('===>Successfully<===');
  }

  static onCreateNewOrderStatusMap(status: string, firebaseService: FirebaseService, storeId: string) {
    let pathOrderMap = FirebaseService.getDocumentMapPath(storeId) + "/" + FirebaseService.orderStatusMapDocument;
    let orderStatusDoc: ActiveOrderStatus = new ActiveOrderStatus();
    orderStatusDoc.documentName = StringUtils.fromEmptyToMinus(status) + '-000000';
    orderStatusDoc.recordCount = 0.0;
    orderStatusDoc.maxRecord = this.systemConfigure.maxRecordOrderStatus;

    let orderStatusMap: OrderStatusMap = new OrderStatusMap();
    orderStatusMap.currentDocument = 0.0;
    orderStatusMap.activeOrderStatusMap.set(orderStatusDoc.documentName, orderStatusDoc);
    firebaseService.onCreateOrUpdateEntryField(pathOrderMap, StringUtils.fromEmptyToMinus(status), orderStatusMap);
  }

  static setSystemConfig(data){
    if(!this.systemConfigure){
      this.systemConfigure = new SystemConfiguration();
    }

    this.systemConfigure.fromJson(data.data() as SystemConfiguration);
    if(this.systemConfigure.cdn.lastUpdate === 0) {
      this.systemConfigure.cdn.lastUpdate = new Date().getTime();
    }
  }

  static getSystemConfig(){
    return this.systemConfigure;
  }

  static getMaxRecordItems(){
    try {
      return this.systemConfigure.maxRecordItems;
    }catch (e) {
      return undefined;
    }
  };

  static getMaxRecordKeywords(){
    try {
      return this.systemConfigure.maxRecordKeywords;
    }catch (e) {
      return undefined;
    }
  };

  static getMaxRecordReport(){
    try {
      return this.systemConfigure.maxRecordReport;
    }catch (e) {
      return undefined;
    }
  };

  static getMaxRecordOrderStatus(){
    try {
      return this.systemConfigure.maxRecordOrderStatus;
    }catch (e) {
      return undefined;
    }
  };

  static getMaxRecordRating(){
    try {
      return this.systemConfigure.maxRecordRating;
    }catch (e) {
      return undefined;
    }
  };

  static getMessageDisplayDay(){
    try {
      return this.systemConfigure.messageDisplayDays;
    }catch (e) {
      return 7;
    }
  };

  static getSubscribeDashboardOrderStatus(){
    try {
      return this.systemConfigure.subscribeDashboardOrderStatus;
    }catch (e) {
      return false;
    }
  };

  static setSubscribeDashboardOrderStatus(value: boolean) {
    this.systemConfigure = new SystemConfiguration();
    this.systemConfigure.subscribeDashboardOrderStatus = value;
  }

  static setSubscribeOrderStatus(value: boolean) {
    this.systemConfigure.subscribeDashboardOrderStatus = value;
  }

  static getIsInventoryDeleteEnable(){
    try {
      return this.systemConfigure.isInventoryDeleteEnable;
    }catch (e) {
      return false;
    }
  };


  static getIsCustomShippingEnable(){
    try {
      return this.systemConfigure.isCustomShippingEnable;
    }catch (e) {
      return false;
    }
  };

  static getIsSingleStoreBCSetting(){
    try {
      return this.systemConfigure.isSingleStoreSetting;
    }catch (e) {
      return false;
    }
  };

  static getIsEmailEnable(){
    try {
      return this.systemConfigure.isEmailEnable;
    }catch (e) {
      return false;
    }
  };

  static getIsPhoneEnable(){
    try {
      return this.systemConfigure.isPhoneEnable;
    }catch (e) {
      return false;
    }
  };

  static getCanUpdateCustomerUser(){
    try {
      return this.systemConfigure.canUpdateCustomerUser;
    }catch (e) {
      return false;
    }
  };

  static getIsTermAndConditionEnable(){
    try {
      return this.systemConfigure.isTermAndConditionEnable;
    }catch (e) {
      return false;
    }
  };

  static clear(){
    this.systemConfigure = undefined;
  }

  static getTags(){
    try {
      return this.systemConfigure.tags;
    }catch (e) {
      return [];
    }
  };
}
