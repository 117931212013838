import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Role} from '../model/role';
import {SharedPreference} from '../shared-preference/shared-perference';
import {profile} from '../inventory/route';
import {SystemConfigUtils} from '../utils/system-config-utils';
import {AuthenticationService} from '../services/authentication.service';


@Injectable({
  providedIn: 'root'
})
export class ReviewService implements CanActivate{

  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!SharedPreference.getUser()) {
      this.authenticationService.onLogout(false);
      return false;
    }
    if (Role.hasVersitaCustomerService(SharedPreference.getUser()) || Role.hasVersitaSupervisor(SharedPreference.getUser())) {
      if (SharedPreference.getUser().isRequireChangePassword) {
        this.router.navigate([profile]);
        return false;
      } else{
        return true;
      }
    }
    if (SharedPreference.getUser().isRequireChangePassword) {
      this.router.navigate([profile]);
      return false;
    } else {
      return (Role.hasCustomerServiceRole(SharedPreference.getUser()) ||
             Role.hasSupervisorRole(SharedPreference.getUser())) && SystemConfigUtils.getIsSingleStoreBCSetting() === false;
    }
  }
}
