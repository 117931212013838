import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {FullComponent} from './layouts/full/full.component';
import {BlankComponent} from './layouts/blank/blank.component';
import {AuthLoginGuard} from "./gards/auth.guard";
import {DashBoardGuardService} from './gards/dash-board-guard.service';
import {UserGuardService} from './gards/user-guard.service';
import {SettingGuardService} from './gards/setting-guard.service';
import {ExportBoardGuardService} from './gards/export-guard.service';
import {OrdersGuardService} from './gards/orders-guard.service';
import {SystemConfigGuardService} from './gards/system-config-guard.service';
import {InventoryGuardService} from './gards/inventory-guard.service';
import {HomePageGuardService} from "./gards/home-page-guard.service";
import {RequestDemoGuard} from "./gards/request-demo-guard";
import {SignupService} from './gards/signup.service';
import {TermConditionGuard} from "./gards/term-condition-guard";
import {ReviewService} from "./gards/Review.service";
import {DiscountByBrandGuardService} from './gards/discount-by-brand-guard.service';
import {ImportService} from "./gards/import.service";
import {ItemSpecialService} from "./gards/ItemSpecial.service";
import {NotificationGuard} from './gards/notification.guard';
import {AboutContactUsGuard} from './gards/about-contact-us.guard';
import {BankGuard} from './gards/bank.guard';
import {WalletGuard} from './gards/wallet.guard';
import { BidDashboardGuardService } from "./gards/bid-dashboard-guard.service";

export const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {path: 'login', loadChildren: './login/login.module#LoginModule'},
      {canActivate: [AuthLoginGuard, SignupService], path: 'signup', loadChildren: './signup/signup.module#SignupModule'},
      {path: 'tables', loadChildren: './table/tables.module#TablesModule'},
      {path: 'forms', loadChildren: './form/forms.module#FormModule'},
      {path: 'icons', loadChildren: './icons/icons.module#IconsModule'},
      {canActivate: [AuthLoginGuard], path: '', redirectTo: '/dashboard', pathMatch: 'full'},
      {canActivate: [AuthLoginGuard, DashBoardGuardService], path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule'},
      {canActivate: [AuthLoginGuard, BidDashboardGuardService], path: 'bid-dashboard', loadChildren: './bid-dashboard/bid-dashboard.module#BidDashboardModule'},
      {canActivate: [AuthLoginGuard], path: 'profile', loadChildren: './profile/profile.module#ProfileModule'},
      {canActivate: [AuthLoginGuard, DiscountByBrandGuardService], path: 'brand-discount', loadChildren: './brand-discount/brand-discount.module#BrandDiscountModule'},
      {canActivate: [AuthLoginGuard, ExportBoardGuardService], path: 'export', loadChildren: './export/export.module#ExportModule'},
      {canActivate: [AuthLoginGuard, OrdersGuardService], path: 'order', loadChildren: './order/order.module#OrderModule'},
      {canActivate: [AuthLoginGuard, UserGuardService], path: 'userAccess', loadChildren: './user-access/user-access.module#UserAccessModule',},
      {canActivate: [AuthLoginGuard, InventoryGuardService], path: 'inventory', loadChildren: './inventory/inventory.module#InventoryModule'},
      {canActivate: [AuthLoginGuard, InventoryGuardService], path: 'versita-stores', loadChildren: './admin-stores/admin-stores.module#AdminStoresModule'},
      {canActivate: [AuthLoginGuard, SettingGuardService], path: 'store-setting', loadChildren: './admin-setting/admin-setting.module#AdminSettingModule'},
      {canActivate: [AuthLoginGuard, TermConditionGuard], path: 'term-condition', loadChildren: './term-condition/term-condition.module#TermConditionModule'},
      {canActivate: [AuthLoginGuard, BankGuard], path: 'bank', loadChildren: './bank/bank.module#BankModule'},
      {canActivate: [AuthLoginGuard, WalletGuard], path: 'wallet', loadChildren: './wallet/wallet.module#WalletModule'},
      {canActivate: [AuthLoginGuard, AboutContactUsGuard], path: 'about-contact-us', loadChildren: './about-contact-us/about-contact-us.module#AboutContactUsModule'},
      {canActivate: [AuthLoginGuard, SystemConfigGuardService], path: "business-configuration", loadChildren: './system-configuration/system-configuration.module#SystemConfigurationModule'},
      {canActivate: [AuthLoginGuard, SystemConfigGuardService], path: 'system-configuration', loadChildren: './system-maintenance/system-maintenance.module#SystemMaintenanceModule'},
      {canActivate: [HomePageGuardService],path: 'home-page', loadChildren: './home-page/home-page.module#HomePageModule'},
      {canActivate: [AuthLoginGuard, RequestDemoGuard], path: 'request-demo', loadChildren: './request-demo/request-demo.module#RequestDemoModule'},
      {canActivate: [AuthLoginGuard, ReviewService], path: 'review', loadChildren: './customer-review/customer-review.module#CustomerReviewModule'},
      {canActivate: [AuthLoginGuard, ImportService], path: 'import', loadChildren: './import/import.module#ImportModule'},
      {path: 'join-as-partner', loadChildren: './join-as-partner/join-as-partner.module#JoinAsPartnerModule'},
      {path: 'partner-register', loadChildren: './partner-register/partner-register.module#PartnerRegisterModule'},
      {canActivate: [AuthLoginGuard, ItemSpecialService], path: 'item-special', loadChildren: './item-special/item-special.module#ItemSpecialModule'},
      {canActivate: [AuthLoginGuard, NotificationGuard], path: 'notifications', loadChildren: './notifications/notifications.module#NotificationsModule'},
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: './authentication/authentication.module#AuthenticationModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), NgbModule.forRoot()],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
