import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FirebaseService} from '../../services/firebase.service';
import {Message} from '../../model/message';
import {StringUtils} from '../../utils/string-utils';
import {SharedPreference} from '../../shared-preference/shared-perference';
import * as firebase from 'firebase';
import {AngularFireDatabase} from '@angular/fire/database';
import {DatabaseReference} from '@angular/fire/database/interfaces';
import {Role} from '../../model/role';
import {SystemConfigUtils} from '../../utils/system-config-utils';
import {ChatsUtils} from '../../utils/chats-utils';
import {IChat} from "../../model/test-chat";
import {RoleUtils} from "../../utils/role-utils";
import {StoreUtils} from '../../utils/store-utils';
import {StoreIdService} from '../../services';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-rightsidebar',
  templateUrl: './rightsidebar.component.html',
  styleUrls: ['./rightsidebar.css'],
})
export class RightSidebarComponent implements OnInit, IChat {
  static instance: RightSidebarComponent;

  updateChat(message: Map<string, Message>) {
    this.setMessageData(message);
  }

  isClick = false;
  isSound = true;
  isUpdate = false;
  name: string;
  keyMessage: string;
  textMessage: string;
  counter: Map<string, number>;
  messageSummary: Map<string, Message>;
  message: Map<string, Message>;
  subscription: Subscription;
  currentStoreId: string;

  // @ts-ignore
  @ViewChild('scrollLast') inputEl: ElementRef;

  constructor(
    private firebaseService: FirebaseService,
    private dbRealTime: AngularFireDatabase,
    private storeIdService: StoreIdService
  ) {
    if(RightSidebarComponent.instance){
      return this;
    }

    RightSidebarComponent.instance = this;
  }

  ngOnInit() {
    if (Role.hasVersitaCustomerService(SharedPreference.getUser())) {
      this.currentStoreId = StoreUtils.getSelectStoreId();
    } else {
      this.currentStoreId = SharedPreference.getStoreId();
    }

    this.getChat();

    this.triggerStoreIdChange();
  }

  getChat() {
    if(SharedPreference.getUser() && (this.isInternalUser() || this.isVersitaUser())) {
      SystemConfigUtils.onGetSystemConfiguration(this.firebaseService, (_) => {
          ChatsUtils.getChats(this.firebaseService, this.currentStoreId);
      });
    }
  }

  isInternalUser(){
    if (!SharedPreference.getUser()) {
      return false;
    }
    return Role.hasInternalUser(SharedPreference.getUser());
  }

  isVersitaUser(){
    if (!SharedPreference.getUser()) {
      return false;
    }
    return Role.hasVersitaUser(SharedPreference.getUser());
  }


  isVersitaAdmin(){
    if (!SharedPreference.getUser()) {
      return false;
    }
    return Role.hasVersitaAdmin(SharedPreference.getUser());
  }

  isVersitaCustomerService() {
    return Role.hasVersitaCustomerService(SharedPreference.getUser());
  }

  isVersitaSupervisor() {
    return Role.hasVersitaSupervisor(SharedPreference.getUser());
  }

  triggerStoreIdChange(){
    this.subscription = this.storeIdService.onGetStoreId().subscribe((value => {
      if (!this.isCustomerService()) {
        return;
      }

      if((this.isVersitaAdmin() && this.isVersitaCustomerService()) || (this.isVersitaAdmin() && this.isVersitaSupervisor())){
        setTimeout(() => {
          StoreUtils.setSelectStoreId(value.storeId);
        }, 3000);
      }else{
        StoreUtils.setSelectStoreId(value.storeId);
      }

      if (value.storeName) {
        StoreUtils.setSelectStoreName(value.storeName);
      }
      this.currentStoreId = value.storeId;
      this.setDataToDefault();
      ChatsUtils.unsubscribeChat();
      ChatsUtils.unsubscribeChatByUid();
      ChatsUtils.counterUnread = 0;
      this.getChat();
    }))
  }

  setDataToDefault() {
    this.counter = new Map<string, number>();
    this.messageSummary = new Map<string, Message>();
    this.message = new Map<string, Message>();
    this.keyMessage = null;
    this.textMessage = null;
    this.name = null;
    this.isClick = false;
    this.isSound = true;
    this.isUpdate = false;
  }

  private isVersitaStore() {
    return RoleUtils.hasRole(SharedPreference.getUser().roles, StringUtils.versitaStore);
  }

  private isVersitaSale() {
    return RoleUtils.hasRole(SharedPreference.getUser().roles, StringUtils.versitaSale);
  }

  scrollToLast(){
    try {
      setTimeout(() => this.inputEl.nativeElement.focus(), 500);
      setTimeout(() => this.isUpdate = false, 2000);
    }catch (e) {}
  }

  playAudio(){
    let audio = new Audio();
    audio.src = StringUtils.alertSound;
    audio.load();
    audio.play().catch(_=>{});
  }

  private openProfileModal(keyMessage){
    this.onCloseMsg();
    this.isClick = true;
    this.isSound = false;
    this.keyMessage = keyMessage;
    let self = this;

    ChatsUtils.getChatByUid(this.firebaseService, keyMessage, this.currentStoreId, (data)=>{
      self.message = new Map();
      let mapData = StringUtils.objToStrMap(data as Message);
      mapData.delete(StringUtils.lastMessageDate);
      mapData.forEach((v, k)=>{
        self.message.set(k, Message.fromJson(v));
      });

      self.updateMessage();
      self.scrollToLast();
    });
  }

  setMessageData(messageData) {
    if (messageData) {
      this.filterMessage(messageData);
    } else {
      this.messageSummary = new Map();
      this.counter = undefined;
    }
  }

  private filterMessage(messagesData: Map<string, Message>) {
    this.messageSummary = new Map();
    this.counter = new Map();

    messagesData.forEach((v, k)=>{
      if(k && v) {
        let countMsg = 0;
        if (!v.isRead && !SharedPreference.getUser().roles.includes(v.role)) {
          countMsg++;
        }

        if (this.isClick && this.keyMessage === k) {
          this.counter.set(k, 0);
        } else {
          this.counter.set(k, countMsg);
        }

        this.messageSummary.set(k, Message.fromJson(v));
      }
    });

    this.counter.forEach((value, key) => {
      if(this.messageSummary !== undefined && this.messageSummary !== null &&
        this.counter.get(key) > 0 && !this.isUpdate && this.isSound){
        this.playAudio();
      }
    });

    this.sortUnreadTo();
  }

  sortUnreadTo() {
    if (this.messageSummary !== null && this.messageSummary !== undefined) {
      let messageSortedDate = Array.from(this.messageSummary.entries()).sort((l, r)=>{
        return r[1].date.localeCompare(l[1].date);
      });

      let messageSortedUnread = messageSortedDate.sort((l, r)=>{
        let compared = !l[1].isRead && r[1].isRead ? -1 : (l[1].isRead && !r[1].isRead ? 1 : 0);
        return compared;
      });

      this.messageSummary = new Map();
      messageSortedUnread.forEach((message)=>{
        this.messageSummary.set(message[0], message[1]);
      });
    }
  }

  getUserRole(msgRole: string){
    if(!SharedPreference.getUser()){
      return undefined;
    }
    if (Role.hasVersitaCustomerService(SharedPreference.getUser())) {
      if (msgRole === StringUtils.roleCustomerService) {
        return true;
      }
    }
    return SharedPreference.getUser().roles.includes(msgRole);
  }

  updateMessage(){
    if(this.isClick){
      this.message.forEach((v, k)=>{
        if(k && v) {
          this.updateData(v, k);
        }
      });
    }
  }

  updateData(chat: Message, key: string){
    let {batch, refMessage, refStore} = this.getReference();
    let field = ".isRead";

    if(!chat.isRead) {
      let mapStore: Map<string, any> = new Map();
      mapStore.set(this.keyMessage + field, true);
      let dataStore = StringUtils.strMapToObj(mapStore);
      batch.update(refStore, dataStore);
    }

    if(!chat.isRead && !SharedPreference.getUser().roles.includes(chat.role)){
      this.isUpdate = true;
      let mapMessage: Map<string, any> = new Map();
      mapMessage.set(key + field, true);
      let dataMessage = StringUtils.strMapToObj(mapMessage);
      batch.update(refMessage, dataMessage);
    }

    batch.commit();
  }

  onAddMessage(){
    let reference: DatabaseReference = this.dbRealTime.database.ref(StringUtils.serverTimeStamp);
    let timestamp = firebase.database.ServerValue.TIMESTAMP;
    reference.child(StringUtils.timestamp).set({
      'timestamp': timestamp
    });
    reference.once("value", (value)=>{
      let timestampRT = value.val()[StringUtils.timestamp][StringUtils.timestamp];
      let mid = timestampRT.toString();
      let senderName = SharedPreference.getUser().firstName+" "+SharedPreference.getUser().lastName;
      senderName = senderName === ' ' ? SharedPreference.getUser().phoneNumber.get(StringUtils.phone) === '' ? SharedPreference.getUser().email : SharedPreference.getUser().phoneNumber.get(StringUtils.code)+SharedPreference.getUser().phoneNumber.get(StringUtils.phone): senderName ;
      if(this.textMessage !== undefined && this.textMessage != null) {
        let chat: Message = new Message();

        chat.text = this.textMessage.trim();
        chat.uid = SharedPreference.getUser().uid;
        chat.senderName = senderName;
        chat.senderPhotoUrl = SharedPreference.getUser().imageUrl;
        chat.date = StringUtils.getDateUTC(new Date());
        chat.isRead = false;
        chat.role =  Role.hasCustomerServiceRole(SharedPreference.getUser())
          ? StringUtils.roleCustomerService : StringUtils.roleCustomer ;
        chat.id = mid;

        this.textMessage = undefined;
        let {batch, refMessage, refCustomer, refStore} = this.getReference();
        let mapMessage: Map<string, any> = new Map();
        mapMessage.set(mid, chat.toMap());
        mapMessage.set(StringUtils.lastMessageDate, firebase.firestore.Timestamp.now());
        let dataMessage = StringUtils.strMapToObj(mapMessage);

        let mapStore: Map<string, any> = new Map();
        mapStore.set(this.keyMessage, chat.toMapSummary());
        let dataStore = StringUtils.strMapToObj(mapStore);

        let mapCustomer: Map<string, any> = new Map();
        mapCustomer.set(this.currentStoreId, chat.toMapSummary());
        let dataCustomer = StringUtils.strMapToObj(mapCustomer);

        batch.set(refCustomer, dataCustomer, {merge: true});
        batch.set(refStore, dataStore, {merge: true});
        batch.set(refMessage, dataMessage, {merge: true});
        batch.commit();
      }
    });
  }

  private getReference() {
    let batch = this.firebaseService.db.firestore.batch();
    let path = FirebaseService.chat + "/" + FirebaseService.chatCollection + '/' + this.currentStoreId + "/" + this.keyMessage;
    let refMessage = this.firebaseService.db.firestore.doc(path);
    let pathCustomer = FirebaseService.chat + "/customer/" + this.keyMessage + "/" + FirebaseService.chatCollection;
    let refCustomer = this.firebaseService.db.firestore.doc(pathCustomer);
    let pathStore = FirebaseService.chat + "/store/" + this.currentStoreId + "/" + FirebaseService.chatCollection;
    let refStore = this.firebaseService.db.firestore.doc(pathStore);
    return {batch, refMessage, refCustomer, refStore};
  }

  toLocalZone(date) {
    return new Date(Date.parse(date));
  }

  isHasReadOnlyRole() {
    return RoleUtils.hasReadOnlyRole();
  }

  getKeyMessage(){
    return Array.from(this.messageSummary.keys());
  }

  isCustomerService() {
    return SharedPreference.getUser() && Role.hasCustomerServiceRole(SharedPreference.getUser());
  }

  isCurrentDate(date) {
    let now = new Date();
    let isCurrent = StringUtils.getDate(date) === StringUtils.getDate(now.toUTCString());
    if(isCurrent){
      return StringUtils.getTime(date);
    }else {
      return StringUtils.getDate(date);
    }
  }

  onCloseMsg(){
    if(this.message) {
      this.message.clear();
    }
    this.isClick = false;
    this.isSound = true;
    ChatsUtils.unsubscribeChatByUid();
  }
}

