import {UserProfile} from '../model/user-profile';

export class UserProfileUtils {
  private static userProfile: Map<string, UserProfile>;


  static getUserProfile(): Map<string, UserProfile> {
    return this.userProfile;
  }

  static setUserProfile(userProfile: UserProfile): void {
    if (!this.userProfile) {
      this.userProfile = new Map();
    }

    this.userProfile.set(userProfile.uid, userProfile);
  }

  static isUserProfileEmpty(uid: string): boolean {
    if (!this.userProfile || this.userProfile.size === 0) {
      return true;
    }

    return Array.from(this.userProfile.keys()).filter(k => k === uid).length === 0;
  }

  static clear(){
    if(this.userProfile){
      this.userProfile.clear();
    }
  }
}
