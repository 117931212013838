import {FirebaseService} from "../services/firebase.service";
import {SharedPreference} from "../shared-preference/shared-perference";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {SubscriptionUtils} from "../utils/subscriptionUtils";
import {environment} from "../../environments/environment";
import {LoggUtilInfo} from '../utils/loggUtil';
import {StringUtils} from '../utils/string-utils';
import * as firebase from 'firebase';
import Swal from "sweetalert2";
import {homePageUrl, loginUrl} from '../inventory/route';
import {StringFieldUtil} from "../utils/string-field-util";
import {User} from "../model/user";
/**
 * Created by cheaphakdey on 19/6/19.
 */

export class UserService{
  private static subscriptionUtils: SubscriptionUtils = new SubscriptionUtils();

  static getUserRole(firebaseService: FirebaseService, router: Router, uid: string) {
    let subscriptionName: string = FirebaseService.user +"/"+ uid;
    if(this.subscriptionUtils.isNotAlreadySubscribe(subscriptionName)) {
      let subscription: Subscription = firebaseService
        .onGetDataById(FirebaseService.user, uid)
        .subscribe(async data => {
            LoggUtilInfo.loggingListen(StringUtils.listenSnapshot + subscriptionName, ` component : user service`);
            let user = data;
            if (user == null || user.isEnable == null || !user.isEnable) {
              this.subscriptionUtils.unsubscribeAll();
              SharedPreference.clearUser();
              SharedPreference.setIsUserLogin(false);
              SharedPreference.isShowingMenu = false;
              SharedPreference.removeAllItem();
              if (environment.isEnableHomePage) {
                await router.navigate(['/home-page'])
              } else {
                await router.navigate([loginUrl]);
              }

            } else {
              SharedPreference.isShowingMenu = true;
            }
            LoggUtilInfo.loggingEnd(StringUtils.listenSnapshot + subscriptionName, ` component : user service`);
          }
        );
      this.subscriptionUtils.subscribe(subscriptionName, subscription);
    }
  }

  static unsubcribUser(){
    this.subscriptionUtils.unsubscribeAll();
  }

  static initRecaptcha(self) {
    StringUtils.delayTimer(function () {
      self.windowRef = self._authenticationService.windowRef;
      self.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
      self.windowRef.recaptchaVerifier.verify();
    }, 100);
  }

  static createUserWithPhone(phone: string, windowRef: any, then: Function, error: Function) {
    let appVerifier = windowRef.recaptchaVerifier;

    firebase.auth().signInWithPhoneNumber(phone, appVerifier).then((data) => {
      windowRef.confirmationResult = data;

      Swal.fire({
        title: `<h4>${StringUtils.enterTempPassword}</h4>`,
        input: 'text',
        inputPlaceholder : StringUtils.temporaryPassword,
        showCancelButton: false,
        confirmButtonText: 'Submit',
        showLoaderOnConfirm: true,
        width:350,
        preConfirm: (temPassword) => {
          if(temPassword === undefined || temPassword === ""){
            return Swal.showValidationMessage(
              StringUtils.temporaryPasswordRequest,
            );
          }else if(temPassword.toString().length < 6){
            return Swal.showValidationMessage(
              StringUtils.temporaryPasswordSixDigits,
            );
          }
        },
      }).then((result) => then(result));
    }).catch((e) => error(e));
  }

  static checkUserWithPhoneExist(firebaseService: FirebaseService, user: User){
    let phone = user.phoneNumber.get(StringUtils.phone);
    return firebaseService.db.collection(FirebaseService.user, ref =>
      ref
        .where(StringFieldUtil.phoneNumber_Phone, "==", phone)
        .where(StringFieldUtil.loginVia, "==", StringFieldUtil.phone)
    ).get();
  }

}
